import React from 'react';
import styled from 'styled-components';
import { IAssetData } from '../../types/asset.type';
import { HeaderAdditionalInfo } from './DataCompanyList';

interface Props {
  data: IAssetData[];
  onClickItem?: (data: IAssetData) => void;
}

const DataAssetList: React.FC<Props> = ({ data, onClickItem }) => {
  return (
    <React.Fragment>
      <HeaderAdditionalInfo>
        <div>Asset List</div>
        <span>Total {data.length}</span>
      </HeaderAdditionalInfo>

      <AssetWrapper>
        {data.map((item, idx) => (
          <ListAssetCard
            key={idx}
            onClick={() => onClickItem && onClickItem(item)}
          >
            <img src='/images/logo-not-found.png' />
            <div>{item.massName}</div>
          </ListAssetCard>
        ))}
      </AssetWrapper>
    </React.Fragment>
  );
};

export default DataAssetList;

const AssetWrapper = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 20px;
  gap: 10px;
`;

const ListAssetCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  /* cursor: pointer; */
  overflow: hidden;
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
  div {
    padding: 10px;
    background-color: white;
    width: 100%;
    font-size: 12px;
  }
`;
