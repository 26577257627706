import { Modal } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import useMapConfig from '../hooks/useMapConfig';
import { appFeatures } from '../screens/application';
import AppIcon from './AppIcon';
import { SubTitle, Title, TitleWrapper } from './ModalChangeCompany';
import { AppFeatureProps } from '../types/config.type';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  onCloseModal: () => void;
}

const ModalChangeApp: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { setCurrentApp, selectedApp } = useMapConfig();
  const [currApp, setCurrApp] = useState<AppFeatureProps>();

  const handleSelectApp = (app: AppFeatureProps) => {
    setCurrApp(app);
  };

  const confirmSelection = () => {
    if (currApp) {
      setCurrentApp(currApp);
      props.onCloseModal();
      switch (currApp.id) {
        case 'fms':
          navigate('/home');
          break;
        case 'bms':
          navigate('/home-bms');
          break;

        default:
          break;
      }
    }
  };

  return (
    <Modal
      width={700}
      open={props.open}
      okText='Confirm Selection'
      onCancel={props.onCloseModal}
      onOk={confirmSelection}
      title={
        <TitleWrapper>
          <Title>Select Application</Title>
          <SubTitle>Select application that you want to use</SubTitle>
        </TitleWrapper>
      }
    >
      <Grid>
        {appFeatures.map((item) => (
          <GridItem
            disabled={item.disabled}
            active={
              currApp
                ? currApp.id === item.id
                : selectedApp && selectedApp.id
                ? selectedApp.id === item.id
                : false
            }
            key={item.id}
            title={item.disabled ? 'Under Development' : undefined}
            onClick={() => handleSelectApp(item)}
          >
            <span>
              <AppIcon
                id={item.id}
                disabled={item.disabled}
                style={{ width: 32 }}
              />
            </span>
            <TitleItem
              disabled={item.disabled}
              active={currApp ? currApp.id === item.id : false}
            >
              {item.name}
            </TitleItem>
            <SubTitleItem>{item.description}</SubTitleItem>
          </GridItem>
        ))}
      </Grid>
    </Modal>
  );
};

export default ModalChangeApp;

interface IGridItem {
  active: boolean;
  disabled?: boolean;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 20px 0;
`;

const GridItem = styled.div<IGridItem>`
  padding: 24px 20px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  border: ${(props) =>
    props.active ? '1px solid #0957C2' : '1px solid #e8ecf3'};
  box-shadow: 0px 3px 2px 0px #0000000d;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? '#eef0f4' : 'white')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  align-items: center;
  span {
    margin-bottom: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.disabled ? '#E3E7EF' : '#e8ecf3')};
    border-radius: 5px;
  }
`;

const TitleItem = styled.div<IGridItem>`
  font-weight: 600;
  font-size: 16px;
  color: ${(props) =>
    props.disabled ? '#92A1B6' : props.active ? '#0957C2' : '#000'};
`;

const SubTitleItem = styled.div`
  font-size: 12px;
  color: #768499;
`;
