import Highcharts from 'highcharts';
import { EMapTileType } from '../hooks/useMapConfig';

export const position: any = [-3.083721, 116.28989];

export const tabs = ['Company Info', 'Map Settings'];

export const tileLayers = [
  {
    type: EMapTileType.satellite,
    name: 'Google Satelite',
    url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    attribution: 'Google Satelite',
  },
  {
    type: EMapTileType.vector,
    name: 'OpenStreetMap Blue',
    url: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
    attribution: 'Open Street Map',
  },
];

export const zoomConfig = {
  maxZoom: 15,
  minZoom: 4,
  defaultZoom: 10,
};

export const intervalOptions = [
  {
    value: 'MINUTE',
    label: 'Minutes',
  },
  {
    value: 'HOUR',
    label: 'Hourly',
  },
  {
    value: 'DAY',
    label: 'Daily',
  },
  {
    value: 'WEEK',
    label: 'Weekly',
  },
  {
    value: 'MONTH',
    label: 'Monthly',
  },
];

export const rangeOptions = [
  {
    value: 'last_days_30',
    label: 'Last 30 Days',
  },
  {
    value: 'last_month',
    label: 'Last Month',
  },
  {
    value: 'last_days_7',
    label: 'Last 7 Days',
  },
  {
    value: 'last_week',
    label: 'Last Week',
  },
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'last_day',
    label: 'Last Day',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];

export const dummyMapInfo = [
  {
    label: 'Latitude',
    value: '-6.245123',
    iconType: 'marker',
  },
  {
    label: 'Longitude',
    value: '107.43267',
    iconType: 'marker',
  },
  {
    label: 'Time UTC',
    value: '2022-10-27 08:23:10 UTC',
    iconType: 'time',
  },
  {
    label: 'Time Local',
    value: '2022-10-27 15:23:10 WIB',
    iconType: 'time',
  },
  {
    label: 'Speed',
    value: '0.20',
    iconType: 'dashboard',
    unit: 'nmi',
  },
  {
    label: 'Heading',
    value: '156°',
    iconType: 'heading',
  },
];

export const optionsGauge = {
  chart: {
    type: 'gauge',
    plotBackgroundColor: null,
    plotBackgroundImage: null,
    plotBorderWidth: 0,
    plotShadow: false,
    height: '210px',
  },

  title: {
    text: null,
  },

  credits: {
    enabled: false,
  },

  pane: {
    startAngle: -90,
    endAngle: 89.9,
    background: null,
    center: ['50%', '90%'],
    size: '160%',
    innerSize: '30%',
  },

  // the value axis
  yAxis: {
    min: 0,
    max: 2000,
    tickPixelInterval: 100,
    tickPosition: 'inside',
    tickColor: 'white',
    tickLength: 20,
    tickWidth: 0,
    height: 100,
    innerRadius: '75%',
    minorTickInterval: null,
    labels: {
      distance: 15,
      style: {
        fontSize: '14px',
      },
    },
    plotBands: [
      {
        from: 0,
        to: 900,
        color: '#55C95A', // green
        thickness: 60,
      },
      {
        from: 900,
        to: 1200,
        color: '#FFBC00', // yellow
        thickness: 60,
      },
      {
        from: 1200,
        to: 2000,
        color: '#FE4545', // red
        thickness: 60,
      },
    ],
  },

  series: [
    {
      name: 'Average RPM',
      data: [80],
      tooltip: {
        valueSuffix: ' RPM',
      },
      dataLabels: {
        format: '{y} AVG. RPM',
        borderWidth: 0,
        color: '#FFF',
        style: {
          fontSize: '14px',
        },
        backgroundColor: 'black',
        zIndex: 5,
      },
      dial: {
        radius: '80%',
        backgroundColor: 'black',
        baseWidth: 16,
        baseLength: '0%',
        rearLength: '0%',
      },
      pivot: {
        backgroundColor: 'gray',
        radius: 6,
      },
    },
  ],
};

export const rpmFuelConsSpeed = {
  chart: {
    zoomType: 'xy',
    height: 480,
    // width: "100%",
    reflow: true,
    animation: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0.2,
      pointPadding: 0,
    },
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      title: {
        text: 'RPM',
        style: {
          color: 'black',
        },
      },
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      // opposite: true,
    },
    {
      // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Liters',
        style: {
          color: '#ffa216',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#ffa216',
        },
      },
      opposite: false,
      lineColor: '#ffa216',
      lineWidth: 1,
      tickColor: '#ffa216',
      tickWidth: 1,
      tickLength: 5,
    },
    {
      // Tertiary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Knot',
        style: {
          color: '#F34584',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#F34584',
        },
      },
      opposite: true,
      lineColor: '#F34584',
      lineWidth: 1,
      tickColor: '#F34584',
      tickWidth: 1,
      tickLength: 5,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   // layout: "vertical",
  //   align: "center",
  //   // x: 180,
  //   // verticalAlign: "top",
  //   // y: 25,
  //   // floating: true,
  //   backgroundColor: "#FFFFFF",
  // },
  series: [
    {
      name: 'Port RPM',
      type: 'column',
      yAxis: 0,
      data: [],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#6F57E9',
    },
    {
      name: 'Starboard RPM',
      type: 'column',
      yAxis: 0,
      data: [],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#02CCCC',
    },
    {
      name: 'Liters',
      type: 'line',
      yAxis: 1,
      data: [],
      marker: {
        enabled: true,
        symbol: 'circle',
      },
      tooltip: {
        valueSuffix: ' liter',
      },
      color: '#F34584',
    },
    {
      name: 'Speed',
      type: 'line',
      yAxis: 2,
      data: [],
      marker: {
        enabled: true,
        symbol: 'circle',
      },
      dashStyle: 'dash',
      tooltip: {
        valueSuffix: ' knot',
      },
      color: '#FF9A3D',
    },
  ],
};

export const rpmSpeed = {
  chart: {
    zoomType: 'xy',
    height: 480,
    // width: "100%",
    reflow: true,
    animation: false,
  },
  responsive: {},
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0.2,
      pointPadding: 0,
    },
  },
  xAxis: [
    {
      categories: [],
      // categories: [
      //   "Jan",
      //   "Feb",
      //   "Mar",
      //   "Apr",
      //   "May",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Nov",
      //   "Dec",
      // ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      title: {
        text: 'RPM',
        style: {
          color: 'black',
        },
      },
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      // opposite: true,
    },
    {
      // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Liters',
        style: {
          color: '#ffa216',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#ffa216',
        },
      },
      opposite: false,
      lineColor: '#ffa216',
      lineWidth: 1,
      tickColor: '#ffa216',
      tickWidth: 1,
      tickLength: 5,
    },
    {
      // Tertiary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Knot',
        style: {
          color: '#F34584',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#F34584',
        },
      },
      opposite: true,
      lineColor: '#F34584',
      lineWidth: 1,
      tickColor: '#F34584',
      tickWidth: 1,
      tickLength: 5,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   // layout: "vertical",
  //   align: "center",
  //   // x: 180,
  //   // verticalAlign: "top",
  //   // y: 25,
  //   // floating: true,
  //   backgroundColor: "#FFFFFF",
  // },
  series: [
    {
      name: 'Port RPM',
      type: 'column',
      yAxis: 0,
      data: [],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#6F57E9',
    },
    {
      name: 'Starboard RPM',
      type: 'column',
      yAxis: 0,
      data: [],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#02CCCC',
    },
    {
      name: 'Liters',
      type: 'line',
      yAxis: 1,
      data: [],
      marker: {
        enabled: true,
        symbol: 'circle',
      },
      tooltip: {
        valueSuffix: ' liter',
      },
      color: '#F34584',
    },
    {
      name: 'Speed',
      type: 'line',
      yAxis: 2,
      data: [],
      marker: {
        enabled: true,
        symbol: 'circle',
      },
      dashStyle: 'dash',
      tooltip: {
        valueSuffix: ' knot',
      },
      color: '#FF9A3D',
    },
  ],
};

export const dummyMultiItemCharts2 = {
  chart: {
    zoomType: 'xy',
    height: 480,
    // width: "100%",
    reflow: true,
    animation: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  xAxis: [
    {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      title: {
        text: 'Liters',
        style: {
          color: 'black',
        },
      },
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      // opposite: true,
    },
    {
      // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Hours',
        style: {
          color: '#FFA620',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#FFA620',
        },
      },
      opposite: true,
      lineColor: '#FFA620',
      lineWidth: 1,
      tickColor: '#FFA620',
      tickWidth: 1,
      tickLength: 5,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   // layout: "vertical",
  //   align: "center",
  //   // x: 180,
  //   // verticalAlign: "top",
  //   // y: 25,
  //   // floating: true,
  //   backgroundColor: "#FFFFFF",
  // },
  series: [
    {
      name: 'Port RPM',
      type: 'column',
      yAxis: 0,
      data: [
        7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
      ],
      tooltip: {
        valueSuffix: ' Liters',
      },
      color: '#6F57E9',
    },
    {
      name: 'Running Hour',
      type: 'line',
      yAxis: 1,
      data: [6.0, 5.9, 8.5, 10.5, 9.2, 5.5, 15.2, 20.5, 20.3, 13.3, 8.9, 9.6],
      tooltip: {
        valueSuffix: ' Hour',
      },
      color: '#FFA620',
    },
  ],
};

export const dummyMultiItemCharts3 = {
  chart: {
    zoomType: 'xy',
    height: 480,
    // width: "100%",
    reflow: true,
    animation: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  xAxis: [
    {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      title: {
        text: 'Liters',
        style: {
          color: 'black',
        },
      },
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      // opposite: true,
    },
    {
      // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Hours',
        style: {
          color: '#FFA620',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#FFA620',
        },
      },
      opposite: true,
      lineColor: '#FFA620',
      lineWidth: 1,
      tickColor: '#FFA620',
      tickWidth: 1,
      tickLength: 5,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   // layout: "vertical",
  //   align: "center",
  //   // x: 180,
  //   // verticalAlign: "top",
  //   // y: 25,
  //   // floating: true,
  //   backgroundColor: "#FFFFFF",
  // },
  series: [
    {
      name: 'Stb. RPM',
      type: 'column',
      yAxis: 0,
      data: [
        7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
      ],
      tooltip: {
        valueSuffix: ' Liters',
      },
      color: '#02CCCC',
    },
    {
      name: 'Running Hour',
      type: 'line',
      yAxis: 1,
      data: [6.0, 5.9, 8.5, 10.5, 9.2, 5.5, 15.2, 20.5, 20.3, 13.3, 8.9, 9.6],
      tooltip: {
        valueSuffix: ' Hour',
      },
      color: '#FFA620',
    },
  ],
};

export const dummyMultiItemCharts4 = {
  chart: {
    zoomType: 'xy',
    height: 480,
    // width: "100%",
    reflow: true,
    animation: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0.1,
      pointPadding: 0,
    },
  },
  xAxis: [
    {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      title: {
        text: 'RPM',
        style: {
          color: 'black',
        },
      },
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      // opposite: true,
    },
    {
      // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Liters',
        style: {
          color: 'black',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      opposite: true,
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      plotLines: [
        {
          color: '#FFBC00',
          width: 2,
          value: 14.2,
          dashStyle: 'dash',
          zIndex: 10,
          label: {
            text: 'Avg. 14.2 Liter',
            align: 'right',
            x: 0,
            y: -5,
            style: {
              fontSize: 14,
              fontWeight: 'bold',
              color: '#FFBC00',
            },
          },
        },
        {
          color: '#FE4545',
          width: 2,
          value: 26.5,
          dashStyle: 'dash',
          zIndex: 10,
          label: {
            text: 'Max. 26.5 Liter',
            align: 'right',
            x: 0,
            y: -5,
            style: {
              fontSize: 14,
              fontWeight: 'bold',
              color: '#FE4545',
            },
          },
        },
      ],
      max: 40,
      min: 0,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   // layout: "vertical",
  //   align: "center",
  //   // x: 180,
  //   // verticalAlign: "top",
  //   // y: 25,
  //   // floating: true,
  //   backgroundColor: "#FFFFFF",
  // },
  series: [
    {
      name: 'Port RPM',
      type: 'column',
      yAxis: 0,
      data: [
        7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
      ],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#6F57E9',
    },
    {
      name: 'Starboard RPM',
      type: 'column',
      yAxis: 0,
      data: [6.0, 5.9, 8.5, 10.5, 9.2, 5.5, 15.2, 20.5, 20.3, 13.3, 8.9, 9.6],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#02CCCC',
    },
  ],
};

export const dummyMultiItemCharts5 = {
  chart: {
    zoomType: 'xy',
    height: 480,
    // width: "100%",
    reflow: true,
    animation: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0.1,
      pointPadding: 0,
    },
  },
  xAxis: [
    {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      title: {
        text: 'RPM',
        style: {
          color: 'black',
        },
      },
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      // opposite: true,
    },
    {
      // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Liters',
        style: {
          color: 'black',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: 'black',
        },
      },
      opposite: true,
      lineColor: 'rgba(0,0,0,.1)',
      lineWidth: 1,
      tickColor: 'rgba(0,0,0,.1)',
      tickWidth: 1,
      tickLength: 5,
      plotLines: [
        {
          color: '#FFBC00',
          width: 2,
          value: 14.2,
          dashStyle: 'dash',
          zIndex: 10,
          label: {
            text: 'Avg. 14.2 Liter',
            align: 'right',
            x: 0,
            y: -5,
            style: {
              fontSize: 14,
              fontWeight: 'bold',
              color: '#FFBC00',
            },
          },
        },
        {
          color: '#FE4545',
          width: 2,
          value: 26.5,
          dashStyle: 'dash',
          zIndex: 10,
          label: {
            text: 'Max. 26.5 Liter',
            align: 'right',
            x: 0,
            y: -5,
            style: {
              fontSize: 14,
              fontWeight: 'bold',
              color: '#FE4545',
            },
          },
        },
      ],
      max: 40,
      min: 0,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   // layout: "vertical",
  //   align: "center",
  //   // x: 180,
  //   // verticalAlign: "top",
  //   // y: 25,
  //   // floating: true,
  //   backgroundColor: "#FFFFFF",
  // },
  series: [
    {
      name: 'AE 1 RPM',
      type: 'column',
      yAxis: 0,
      data: [
        7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
      ],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#6BA6FF',
    },
    {
      name: 'AE 2 RPM',
      type: 'column',
      yAxis: 0,
      data: [6.0, 5.9, 8.5, 10.5, 9.2, 5.5, 15.2, 20.5, 20.3, 13.3, 8.9, 9.6],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#163E8A',
    },
    {
      name: 'AE 3 RPM',
      type: 'column',
      yAxis: 0,
      data: [6.0, 5.9, 8.5, 10.5, 9.2, 5.5, 15.2, 20.5, 20.3, 13.3, 8.9, 9.6],
      tooltip: {
        valueSuffix: ' RPM',
      },
      color: '#35CD69',
    },
  ],
};
