import { Select } from 'antd';
import { Clock, Compass, MapPin } from 'react-feather';
import styled from 'styled-components';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

interface ICardInfo {
  label: string;
  value: string;
  unit?: string;
  iconType?: 'marker' | 'time' | 'dashboard' | 'compass' | string | undefined;
  style?: React.CSSProperties;
  smallValue?: boolean;
  dropdown?: boolean;
  onChange?: (value: any) => void;
  options?: { value: string; label: string }[];
  showChart?: boolean;
}

const CardInfo: React.FC<ICardInfo> = (props) => {
  const _renderIcon = () => {
    const color = '#9FACBF';
    const size = 16;
    if (props.iconType === 'marker') {
      return <MapPin size={size} color={color} />;
    } else if (props.iconType === 'time') {
      return <Clock size={size} color={color} />;
    } else if (props.iconType === 'dashboard') {
      return <Clock size={size} color={color} />;
    } else if (props.iconType === 'compass') {
      return <Compass size={size} color={color} />;
    }
  };

  const handleClickDropdown = (data: any) => {
    props.onChange && props.onChange(data);
  };

  return (
    <WrapperCardInfo style={props.style}>
      <FlexBetween>
        <span>{props.label}</span>
        {props.iconType && _renderIcon()}
      </FlexBetween>
      <Flex>
        <TextValue smallValue={props.smallValue}>{props.value}</TextValue>
        {props.dropdown && props.unit ? (
          <Select
            size='small'
            defaultValue={props.unit}
            style={{ width: 120, marginLeft: 10 }}
            onChange={handleClickDropdown}
            options={props.options}
          />
        ) : props.unit ? (
          <span>{props.unit}</span>
        ) : (
          false
        )}
      </Flex>
      {props.showChart && (
        <div style={{ width: '100%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'bar',
                height: 65,
                spacingRight: 0,
                spacingLeft: 0,
                // spacingTop: 0,
                // spacingBottom: 0,
                // margin: [0, 0, 0, 0],
              },
              title: null,
              credits: false,
              legend: false,
              tooltip: false,
              xAxis: {
                visible: false,
              },
              yAxis: {
                gridLineWidth: 3,
                title: {
                  enabled: false,
                  visible: false,
                  /* text: 'Goals' */
                },
                // min: 0,
                // plotLines: [
                //   {
                //     color: '#FF0000',
                //     width: 10,
                //     value: 5.5,
                //     zIndex: 5,
                //   },
                // ],
                labels: {
                  style: {
                    fontSize: 10,
                  },
                },
              },
              plotOptions: {
                bar: {
                  groupPadding: 0,
                  pointPadding: 0,
                },
              },
              series: [
                {
                  name: 'Percentage',
                  data: [parseFloat(props.value)],
                  color: 'rgba(0, 128, 0, .56)',
                  dataLabels: {
                    enabled: false,
                  },
                },
              ],
            }}
            allowChartUpdate={true}
            immutable={true}
          />
        </div>
      )}
    </WrapperCardInfo>
  );
};

export default CardInfo;

const WrapperCardInfo = styled.div`
  border-radius: 8px;
  background-color: white;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
`;

interface TextValueProps {
  smallValue?: boolean;
}

const TextValue = styled.div<TextValueProps>`
  font-weight: 600;
  font-size: ${(props) => (props.smallValue ? '22px' : '22px')};
`;
