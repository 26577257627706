import formatcoords from 'formatcoords';
import { useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import styled from 'styled-components';

interface Props {
  showSummary: boolean;
}

const MapEventCoordinate: React.FC<Props> = ({ showSummary }) => {
  const [latLng, setLatLng] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const map = useMapEvents({
    click() {
      // map.locate();
    },
    mousemove(e) {
      setLatLng(e.latlng);
    },
  });

  return (
    <CoordWrapper id='rambona' showSummary={showSummary}>
      {formatcoords(latLng.lat, latLng.lng).format('DD MM ss X', {
        latLonSeparator: ', ',
        decimalPlaces: 0,
      })}
    </CoordWrapper>
  );
};

const CoordWrapper = styled.div<Props>`
  padding: 4px 16px;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  z-index: 998;
  bottom: 10px;
  left: ${(props) => (props.showSummary ? '390px' : '10px')};
`;

export default MapEventCoordinate;
