export type IPagination = {
  page: number;
  itemPerPage: number;
  totalData: number;
  totalPage: number;
  data: number;
};

export const INITIAL_PAGINATION: IPagination = {
  page: 1,
  itemPerPage: 25,
  totalData: 0,
  totalPage: 1,
  data: 0,
};

export type IQuery<ExtendType> = ExtendType & {
  page?: number;
  max?: number;
};

export const INITIAL_QUERY: IQuery<any> = {
  page: 1,
  max: 25,
};

export type IResponseStatus = {
  error: number;
  errorCode: number;
  message: string;
};

export type IResponsePagination<ResultItem> = {
  data: Array<ResultItem>;
  fields: any;
  pagination: IPagination;
  status: IResponseStatus;
};

export interface IResponseSingle<ResultItem> {
  data: ResultItem;
  fields: any;
  status: IResponseStatus;
}

export const initialResponseStatus: IResponseStatus = {
  error: 0,
  errorCode: 0,
  message: '',
};
