import { DataFilterProps, initialDataFilter } from '../types/config.type';
import { createGlobalState } from 'react-hooks-global-state';
import type { RangePickerProps } from 'antd/es/date-picker';
import { intervalOptions, rangeOptions } from '../helpers/map';
import moment from 'moment';

interface FilterProps {
  isLoadingFilter: boolean;
  dataFilter: DataFilterProps;
}

const initialState: FilterProps = {
  isLoadingFilter: false,
  dataFilter: { ...initialDataFilter },
};

const { useGlobalState } = createGlobalState(initialState);

const useFilter = () => {
  const [dataFilter, setDataFilter] = useGlobalState('dataFilter');
  const [isLoadingFilter, setIsLoadingFilter] =
    useGlobalState('isLoadingFilter');

  const onChangeDateRange: RangePickerProps['onChange'] = (
    dates,
    dateStrings
  ) => {
    if (dates) {
      setDataFilter((oldData) => ({
        ...oldData,
        rangeType: rangeOptions[6].value,
        range: {
          startAt: moment(dateStrings[0]),
          endAt: moment(dateStrings[1]),
        },
      }));
    } else {
      console.log('Clear');
    }
  };

  const onChangeAsset = (massId: string) => {
    setDataFilter((oldData) => ({ ...oldData, massId }));
  };

  const onChangeRangeType = async (value: string) => {
    let rangeType: any;

    const found = rangeOptions.find((item) => item.value === value);

    if (found) {
      rangeType = found.value;
    }

    let interval = dataFilter.interval;
    let startAt = moment();
    let endAt = moment();
    if (value === 'today' || value === 'last_day') {
      interval = intervalOptions[0].value;
    } else {
      interval = intervalOptions[2].value;
    }
    switch (value) {
      case 'last_days_30':
        startAt = moment().subtract(30, 'day');
        endAt = moment();
        break;
      case 'last_month':
        startAt = moment()
          .subtract(1, 'month')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 });
        endAt = moment()
          .subtract(1, 'month')
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 });
        break;
      case 'last_days_7':
        startAt = moment().subtract(7, 'days');
        endAt = moment();
        break;
      case 'last_week':
        startAt = moment().subtract(1, 'week').startOf('week');
        endAt = moment();
        break;
      case 'today':
        startAt = moment().set({ hour: 0, minute: 0, second: 0 });
        endAt = moment();
        break;

      default:
        break;
    }
    setDataFilter((oldVal) => ({
      ...oldVal,
      rangeType,
      interval,
      range: {
        startAt,
        endAt,
      },
    }));
  };

  const onChangeInterval = (interval: string) => {
    setDataFilter((oldVal) => ({
      ...oldVal,
      interval,
    }));
  };

  return {
    setDataFilter,
    setIsLoadingFilter,
    dataFilter,
    isLoadingFilter,
    onChangeDateRange,
    onChangeAsset,
    onChangeRangeType,
    onChangeInterval,
  };
};

export default useFilter;
