import { IResponseStatus } from '../helpers/pagination';
import { IAssetData } from './asset.type';
import { IGroupCompanyData, initialGroupCompany } from './groupCompany.type';

export enum EApplicationType {
  FMS = 'fuel_management_system',
  AM = 'asset_management',
  BM = 'bunkering_management',
  TLM = 'tank_level_management',
}

export interface CompanyProps {
  compAddress: string;
  compCreatedTime: string;
  compCreatedUserId: string;
  compGrcp: IGroupCompanyData;
  compGrcpId: string;
  compId: string;
  compName: string;
  compPIC?: string;
  compPICPhone?: string;
  compPhone?: string;
  compStatus: string;
  compUpdatedTime: string;
  compUpdatedUserId: string;
  compImage?: string;
  compAsset: IAssetData[];
}

export const initialCompany: CompanyProps = {
  compAddress: '',
  compCreatedTime: '',
  compCreatedUserId: '',
  compGrcp: initialGroupCompany,
  compGrcpId: '',
  compId: '',
  compName: '',
  compPIC: '',
  compPICPhone: '',
  compPhone: '',
  compStatus: '',
  compUpdatedTime: '',
  compUpdatedUserId: '',
  compAsset: [],
};

export interface ICompanyData {
  compId: string;
  compGrcpId: string;
  compName: string;
  compAddress: string;
  compPhone: string;
  compPIC: string;
  compPICPhone: string;
  compStatus: string;
  compCreatedUserId: string;
  compCreatedTime: string;
  compUpdatedUserId: string;
  compUpdatedTime: string;
  compImage?: string;
  compGrcp: IGroupCompanyData;
  compAsset: IAssetData[];
}

export const initialCompanyType: ICompanyData = {
  compId: '',
  compGrcpId: '',
  compName: '',
  compAddress: '',
  compPhone: '',
  compPIC: '',
  compPICPhone: '',
  compStatus: '',
  compCreatedUserId: '',
  compCreatedTime: '',
  compUpdatedUserId: '',
  compUpdatedTime: '',
  compGrcp: {
    grcpId: '',
    grcpName: '',
    grcpNickName: '',
    grcpAddress: '',
    grcpCity: '',
    grcpTelp: '',
    grcpFax: '',
    grcpCreatedUserId: '',
    grcpCreatedTime: '',
    grcpUpdatedUserId: '',
    grcpUpdatedTime: '',
    companies: [],
  },
  compAsset: [],
};

export interface ICompAuthStatus {
  compGrcp: ICompGrcpAuthStatus;
  compGrcpId: string;
  compId: string;
  compName: string;
  compImage?: string;
}

export interface ICompGrcpAuthStatus {
  grcpId: string;
  grcpName: string;
  grcpNickName: string;
}

export interface ISetCompAuth {
  response: boolean;
  status: IResponseStatus;
}

export const initialCompAuthStatus: ICompAuthStatus = {
  compId: '',
  compGrcpId: '',
  compName: '',
  compGrcp: {
    grcpId: '',
    grcpName: '',
    grcpNickName: '',
  },
};
