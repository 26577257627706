import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { httpRequest } from '../helpers/api';
import { ICompanyData } from '../types/company.type';
import { DefaultResponseListProps } from '../types/config.type';

const CompanySvc = {
  getCompanies(config: AxiosRequestConfig<any>) {
    return httpRequest.get(`/master/company`, config);
  },
  getCompanyById(
    id: any,
    config?: any
  ): Promise<AxiosResponse<DefaultResponseListProps<ICompanyData>>> {
    return httpRequest.get(`/master/company/${id}`, config);
  },
  getCompanyByName(param: any) {
    return httpRequest.get(`/master/company`, { params: param });
  },
  getCompany(params: any) {
    return httpRequest.get(`/master/company`, { params });
  },
  getCompanyByGroupId(param: any) {
    return httpRequest.get(
      `/master/company?findField=${param.findField}&findValue=${param.findValue}`
    );
  },
  addCompany(data: any) {
    return httpRequest.post(`/master/company`, data);
  },
  updateCompany(id: any, data: any) {
    return httpRequest.put(`/master/company/${id}`, data);
  },
  deleteCompany(id: any) {
    return httpRequest.delete(`/master/company/${id}`);
  },
};

export default CompanySvc;
