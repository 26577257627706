import { baseUrl } from '../helpers/api';

interface Props {
  compImage?: string;
}

const CompanyImage: React.FC<Props> = (props) => {
  if (!props.compImage) {
    return <img alt='company flag' src='/images/logo-not-found.png' />;
  }
  return <img alt='company flag' src={`${baseUrl}/${props.compImage}`} />;
};

export default CompanyImage;
